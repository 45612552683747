import React, { useState } from 'react'
import { InfiniteData } from 'react-query'

import {
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
} from '@floating-ui/react'
import { Trans } from '@lingui/macro'
import cn from '@meltdownjs/cn'

import { ChevronDownIcon } from '@heroicons/react/20/solid'

import { CompanyUser } from 'src/api/company-user-search/types'

import useDefaultCompanyUser from 'src/hooks/data/useDefaultCompanyUser'
import usePaginatedProducts from 'src/hooks/data/usePaginatedProducts'

import Img from 'src/components/Img'

type SelectItemProps = {
  collections: string[]
  isHighlighted?: boolean
  isSelected?: boolean
  thumbnail: string
  stockStatus: number
  style: string
} & React.HTMLAttributes<HTMLDivElement>

const SelectItem = React.memo(
  React.forwardRef<HTMLDivElement, SelectItemProps>(
    (
      {
        collections,
        isHighlighted,
        isSelected,
        thumbnail,
        stockStatus,
        style,
        className,
        ...props
      },
      ref
    ) => {
      return (
        <div
          ref={ref}
          className={cn(
            'flex h-12 cursor-pointer items-center space-x-4',
            isHighlighted && 'bg-gray-200',
            isSelected && 'bg-gray-300',
            className
          )}
          {...props}
        >
          <div className="relative w-14">
            <Img
              className=" rounded-full bg-white object-cover"
              src={thumbnail}
              alt={style}
              spinnerSize="50%"
              spinnerThickness={2}
              placeholderClassName="w-2/3"
            />
          </div>
          <div className="flex w-full justify-start truncate">
            <div className="flex flex-col truncate font-medium" title={style}>
              <span className="self-start">{style}</span>
              <span className="self-start text-xs">{collections.at(-1)}</span>
            </div>
            {stockStatus === 0 && (
              <div className="float-right ml-auto truncate bg-red-100 px-5 py-1 font-medium text-red-600">
                <span>
                  <Trans>Sold out</Trans>
                </span>
              </div>
            )}
          </div>
        </div>
      )
    }
  )
)

type StyleSwitcherProps = {
  onChange: (product: any) => void
  product: any
}

const selectorFn = (data: InfiniteData<any>) => {
  const items = data.pages.flatMap((page: any) => page.abstractProducts)

  return items.map((item) => ({
    style: item.style,
    styleKey: item.style_key,
    thumbnail: item.thumbnail,
    product: item,
  }))
}

const StyleSwitcher: React.FC<StyleSwitcherProps> = ({ onChange, product }) => {
  const defaultCompanyUserQuery = useDefaultCompanyUser<CompanyUser>()
  const companyUser = defaultCompanyUserQuery.data
  const productsQuery = usePaginatedProducts(
    {
      companyUserReference: companyUser?.companyUserReference,
      onePerGroupHash: true,
      modelKey: product.model_key,
    },
    {
      enabled: !!product.model_key,
      select: selectorFn,
    }
  )

  const [isOpen, setIsOpen] = React.useState(false)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  const handleSelect = React.useCallback((product: number | null) => {
    onChange(product)
    setIsOpen(false)
  }, [])

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom-end',
    middleware: [
      offset({ mainAxis: 2 }),
      size({
        apply({ availableWidth, elements }) {
          elements.floating.style.maxWidth = `${availableWidth - 20}px`
        },
      }),
    ],
  })

  const click = useClick(context)
  const dismiss = useDismiss(context, { ancestorScroll: true })

  const listRef = React.useRef<(HTMLElement | null)[]>([])

  const listNavigation = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    focusItemOnHover: true,
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [listNavigation, click, dismiss]
  )

  return (
    <div className="w-full">
      <button
        ref={refs.setReference}
        className="flex h-12 w-full items-center justify-between rounded-md border border-gray-400 px-3"
        {...getReferenceProps({
          onClick: (event) => {
            event.stopPropagation()
          },
        })}
      >
        <SelectItem
          collections={product.collection}
          style={product.style}
          thumbnail={product.thumbnail}
        />
        <div>
          <ChevronDownIcon
            className={'h-5 w-5 transition duration-300'}
            style={{ transform: isOpen ? 'scaleY(-1)' : '' }}
          />
        </div>
      </button>

      {isOpen && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className="max-h-56 w-full overflow-scroll rounded-lg bg-white shadow-xl"
          {...getFloatingProps()}
        >
          {productsQuery.data?.map((item: any, index: number) => (
            <SelectItem
              tabIndex={activeIndex === index ? 0 : -1}
              ref={(node) => {
                if (!!node) {
                  listRef.current[index] = node
                }
              }}
              collections={item.product.collection}
              key={index}
              style={item.style}
              stockStatus={item.product.stockStatus}
              thumbnail={item.thumbnail}
              isHighlighted={activeIndex === index}
              isSelected={
                product.style === item.style &&
                product.collection.at(-1) === item.product.collection.at(-1)
              }
              className="px-3"
              {...getItemProps({
                onClick: () => {
                  handleSelect(item.product)
                },
              })}
            />
          ))}
          {null}
        </div>
      )}
    </div>
  )
}

export default StyleSwitcher
