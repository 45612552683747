import React, { Fragment } from 'react'
import { Link, useParams } from 'react-router-dom'

import { Trans } from '@lingui/macro'
import Spinner from 'react-svg-spinner'

import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/20/solid'
import { ArrowRightIcon } from '@heroicons/react/24/outline'

import useCart from 'src/hooks/data/useCart'

import Currency from 'src/components/Currency'

const CartFooter = React.memo(({ cartItemsUpdateStatus }) => {
  const { cartId } = useParams()
  const cartQuery = useCart(cartId, {
    notifyOnChangeProps: 'tracked',
    select: ({ items, totals, currency, validationMessages }) => ({
      totals,
      currency,
      itemsWithMessages: Object.values(items).filter(
        ({ messages }) => messages?.length > 0
      ),
      totalItems: Object.values(items).reduce(
        (sum, { quantity }) => sum + quantity,
        0
      ),
      hasMessages: validationMessages.length > 0,
      hasItemsWithMessages: Object.values(items).some(
        ({ messages }) => messages?.length > 0
      ),
    }),
  })

  const checkoutState =
    cartQuery.data?.totalItems === 0
      ? 'empty'
      : // : cartQuery.data.hasMessages
        // ? 'invalid'
        'valid'

  const cartState =
    cartQuery.data?.totalItems === 0
      ? 'empty'
      : cartQuery.data?.hasMessages || cartQuery.data?.hasItemsWithMessages
      ? 'invalid'
      : 'valid'

  const currencyIsoCode = cartQuery.data?.currency

  return (
    <div className="group relative flex h-full items-center space-x-4">
      <div className="h-10 w-10">
        {cartItemsUpdateStatus === 'loading' ? (
          <span className="text-gray-400">
            <Spinner size="100%" color="currentColor" />
          </span>
        ) : (
          <Fragment>
            {cartState === 'valid' && (
              <span className="text-green-500">
                <CheckCircleIcon className="h-full w-full" />
              </span>
            )}

            {cartState === 'invalid' && (
              <span className="text-red-600">
                <ExclamationCircleIcon className="h-full w-full" />
              </span>
            )}
          </Fragment>
        )}
      </div>

      <div className="leading-snug">
        <div className="font-bold">
          <Currency
            cents={cartQuery.data?.totals.subtotal}
            currencyIsoCode={currencyIsoCode}
          />
        </div>

        <div className="text-sm text-gray-500">
          <Trans
            id="{count, plural, =1 {# product} other {# products}}"
            values={{ count: cartQuery.data?.totalItems }}
          />
        </div>
      </div>

      {checkoutState === 'valid' &&
        checkoutState !== 'empty' &&
        cartItemsUpdateStatus !== 'loading' && (
          <Link
            to={`checkout`}
            className="flex h-10 items-center space-x-2 rounded px-4 text-white bg-gradient-purple hover:bg-gradient-purple-light active:bg-gradient-purple-dark"
          >
            <span>
              <Trans>To checkout</Trans>
            </span>
            <ArrowRightIcon className="w-6" />
          </Link>
        )}

      {cartItemsUpdateStatus === 'loading' && (
        <div className="flex h-10 cursor-wait items-center space-x-2 rounded border border-gray-500 px-4 text-gray-700">
          <span>
            <Trans>To checkout</Trans>
          </span>
          <ArrowRightIcon className="w-6" />
        </div>
      )}

      {cartItemsUpdateStatus !== 'loading' && checkoutState === 'empty' && (
        <div className="flex h-10 cursor-not-allowed items-center space-x-2 rounded border border-gray-500 px-4 text-gray-700">
          <span>
            <Trans>Your cart is empty</Trans>
          </span>
        </div>
      )}
    </div>
  )
})

export default CartFooter
