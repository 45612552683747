import React, { Fragment, useState } from 'react'
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'

import {
  FloatingPortal,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
} from '@floating-ui/react'
import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import cn from '@meltdownjs/cn'
import Lottie from 'lottie-react'

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import {
  EyeIcon,
  Squares2X2Icon,
  TableCellsIcon,
} from '@heroicons/react/24/outline'

import useCustomer from 'src/hooks/data/useCustomer'
import useDefaultCompanyUser from 'src/hooks/data/useDefaultCompanyUser'
import useUpdateCustomer from 'src/hooks/data/useUpdateCustomer'

const CartViewSwitcher = () => {
  const {
    i18n: { locale },
  } = useLingui()

  const [cartViewSwitcherAnimation, setCartViewSwitcherAnimation] =
    React.useState(null)
  const { cartId, companyId } = useParams()
  const { pathname } = useLocation()
  const [isOpen, setIsOpen] = React.useState(false)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const navigate = useNavigate()
  const navigateToList = () => navigate(`/${companyId}/carts/${cartId}`)
  const navigateToGrid = () => navigate(`/${companyId}/carts/${cartId}/grid`)

  const isGrid = !!matchPath('/:companyId/carts/:cartId/grid', pathname)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom-end',
    middleware: [offset(5)],
  })

  const defaultCompanyUserQuery = useDefaultCompanyUser()
  const companyUser = defaultCompanyUserQuery.data
  const click = useClick(context)
  const dismiss = useDismiss(context, { ancestorScroll: true })
  const { mutate: updateCustomer } = useUpdateCustomer(
    companyUser.customer.customerReference
  )
  const { data: customer } = useCustomer()

  const [webUiSetting, setWebUiSetting] = useState(
    customer?.webUiSettings?.customSettingData?.showCartViewAnimation
  )
  const [isAnimationVisible, setIsAnimationVisible] = useState(
    webUiSetting ?? true
  )

  const listRef = React.useRef<(HTMLElement | null)[]>([])

  const listNavigation = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    focusItemOnHover: true,
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    listNavigation,
    click,
    dismiss,
  ])

  React.useEffect(() => {
    import(`src/assets/animations/cart-view-switcher-${locale}.json`).then(
      (animation) => {
        setCartViewSwitcherAnimation(animation.default)
      }
    )

    const timer = setTimeout(() => {
      setIsAnimationVisible(false)
    }, 10000)

    return () => clearTimeout(timer)
  }, [])

  const onUpdateCustomer = () => {
    updateCustomer({
      webUiSettings: {
        customSettingData: {
          showCartViewAnimation: false,
        },
      },
    } as any)

    setWebUiSetting(true)
  }

  if (isAnimationVisible && webUiSetting === undefined) {
    onUpdateCustomer()
  }

  return (
    <Fragment>
      <div className="relative">
        {isAnimationVisible && cartViewSwitcherAnimation && (
          <div className="absolute -right-8 z-10 w-96">
            <Lottie animationData={cartViewSwitcherAnimation} loop={false} />
          </div>
        )}
        <div className="z-10">
          <button
            ref={refs.setReference}
            className="button-color-gray button-size-md relative z-10 ml-auto flex h-10 shrink-0 items-center space-x-2 px-4 lg:h-8"
            {...getReferenceProps({
              onClick: (event) => {
                event.stopPropagation()
              },
            })}
          >
            <span>
              <EyeIcon className="w-4" />
            </span>
            <span className="hidden md:block">
              <Trans>View</Trans>
            </span>
            <span className="ml-auto">
              {isOpen ? (
                <ChevronUpIcon className="w-5" />
              ) : (
                <ChevronDownIcon className="w-5" />
              )}
            </span>
          </button>
        </div>
      </div>

      {isOpen && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className="z-10 w-2/5 rounded-lg bg-white text-sm shadow-xl lg:w-auto"
            {...getFloatingProps()}
          >
            <div
              className={cn(
                'flex h-8 cursor-pointer items-center whitespace-nowrap px-4 py-6 hover:bg-gray-300 focus:ring-0',
                { 'bg-gray-100 text-purple': !isGrid }
              )}
              onClick={navigateToList}
            >
              <span className="text-current">
                <TableCellsIcon className="w-6" />
              </span>
              <span className="flex h-8 cursor-pointer items-center space-x-2 px-4 text-current">
                <Trans>List View</Trans>
              </span>
            </div>
            <hr></hr>
            <div
              className={cn(
                'flex h-8 cursor-pointer items-center whitespace-nowrap px-4 py-6 hover:bg-gray-300 focus:ring-0',
                { 'bg-gray-100 text-purple': isGrid }
              )}
              onClick={navigateToGrid}
            >
              <span className="text-current">
                <Squares2X2Icon className="w-6" />
              </span>
              <span className="flex h-8 cursor-pointer items-center space-x-2 px-4 text-current">
                <Trans>Grid View</Trans>
              </span>
            </div>
          </div>
        </FloatingPortal>
      )}
    </Fragment>
  )
}

export default CartViewSwitcher
