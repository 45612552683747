import { Fragment } from 'react'

import { H2 } from 'src/components/Header'

const TermsOfCancellation = () => {
  return (
    <Fragment>
      <H2>Stornobedingungen</H2>
      <div className="space-y-6">
        <p>
          Stornierungen von Affenzahn Produkten, sowie Accessoires, Special
          Editions, Bundles und Sonderposten der Marken Ergobag und Satch, sind
          nicht gestattet.
        </p>
        <p>
          Der Gesamtstornierungswert eines Geschäftsjahres darf 8% des
          fakturierten Umsatzes des aktuellen Geschäftsjahres nicht
          überschreiten.
        </p>
      </div>
    </Fragment>
  )
}

export default TermsOfCancellation
