import React from 'react'
import { useParams } from 'react-router-dom'

import { Trans } from '@lingui/macro'
import cn from '@meltdownjs/cn'

import { CompanyUser } from 'src/api/company-user-search/types'

import useCart from 'src/hooks/data/useCart'
import useDefaultCompanyUser from 'src/hooks/data/useDefaultCompanyUser'
import useOrderBudget from 'src/hooks/data/useOrderBudget'

import useDefaultCompanyUserPermissions from 'src/hooks/utils/useDefaultCompanyUserPermissions'

import Currency from 'src/components/Currency'

interface OrderBudgetProps extends React.HTMLAttributes<HTMLDivElement> {
  currencyIsoCode: string
}

const OrderBudget: React.FC<OrderBudgetProps> = ({
  currencyIsoCode,
  ...rest
}) => {
  const { cartId } = useParams()
  const { data: cart } = useCart(cartId, { enabled: !!cartId })
  const { data: companyUser } = useDefaultCompanyUser<CompanyUser>()
  const { ability } = useDefaultCompanyUserPermissions()
  const { data: orderBudget, isSuccess } = useOrderBudget(
    companyUser?.companyUserReference!,
    {
      enabled: !!companyUser?.companyUserReference,
    }
  )

  if (
    !isSuccess ||
    !orderBudget ||
    cart?.companyUserReference !== companyUser?.companyUserReference ||
    (!!cart && !ability.can('see', 'orderBudget'))
  ) {
    return null
  }

  if (cart === undefined) {
    // ToDo: Add styling for cart undependent usage
    return (
      <div {...rest}>
        <Trans>Still available weekly budget</Trans>:{' '}
        <Currency
          cents={orderBudget.budget}
          currencyIsoCode={currencyIsoCode}
        />
      </div>
    )
  }

  return (
    <div
      className={cn(
        'flex h-full flex-row-reverse items-center space-x-4 space-x-reverse text-sm md:ml-24 md:flex-col md:items-start md:justify-center md:space-x-0',
        rest.className
      )}
    >
      <div
        className={cn(
          'font-medium text-gray-500 md:font-semibold md:text-gray-700',
          {
            '!text-red-400': orderBudget.budget - cart.totals.subtotal < 0,
          }
        )}
      >
        <Currency
          cents={orderBudget.budget - cart.totals.subtotal}
          currencyIsoCode={currencyIsoCode}
        />
      </div>

      <div className="text-gray-500">
        <Trans>Still available weekly budget</Trans>
        <span className="md:hidden">:</span>
      </div>
    </div>
  )
}

export default OrderBudget
