import React from 'react'

import { t } from '@lingui/macro'

import {
  AdjustmentsVerticalIcon,
  ChevronDoubleUpIcon,
} from '@heroicons/react/24/outline'

import useCart from 'src/hooks/data/useCart'

import Button from 'src/components/Button'
import CartViewSwitcher from 'src/components/CartViewSwitcher'
import Filters from 'src/components/Filters'

import CartFilter from 'src/pages/Carts/Cart/CartFilter'
import ResetFiltersButton from 'src/pages/Carts/Cart/ResetFiltersButton'
import SortSelect from 'src/pages/Carts/Cart/SortSelect'
import SearchInput from 'src/pages/Carts/CartGrid/components/Header/SearchInput'

type HeaderProps = {
  cartId: string
  filterDefinitions: Record<
    string,
    {
      name: string
      label: string
      options: { label: string; value: string }[]
      config: { isMultiValued: boolean }
    }
  >
  sortOptions: Record<string, string>
}

const Header: React.FC<HeaderProps> = ({
  cartId,
  filterDefinitions,
  sortOptions,
}) => {
  const {
    'stock-status': stockStatusFilterDefinition,
    ...restFilterDefinitions
  } = filterDefinitions

  stockStatusFilterDefinition.options = stockStatusFilterDefinition.options.map(
    (option) => ({ ...option, label: t({ id: `stockStatus_${option.value}` }) })
  )

  const [showCartFilters, setShowCartFilters] = React.useState(false)
  const { data } = useCart(cartId, {
    notifyOnChangeProps: 'tracked',
    select: ({ filters }: { filters: any }) => {
      let activeFilters = 0
      Object.values<any>({ ...filters?.searchOptions?.filters })?.forEach(
        (value) => value[0] && (activeFilters += 1)
      )
      return {
        activeFilters,
      }
    },
  })
  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-row space-x-2">
        <div className="flex flex-2 flex-row space-x-2">
          <SearchInput className="min-w-[14rem]" />
          <Button
            size="xl"
            variant="gray"
            className="flex shrink-0 items-center space-x-2 lg:h-8 "
            onClick={() => setShowCartFilters(!showCartFilters)}
          >
            {showCartFilters ? (
              <ChevronDoubleUpIcon className="w-4 shrink-0" />
            ) : (
              <AdjustmentsVerticalIcon className="w-4 shrink-0" />
            )}
            <span className="hidden md:block">
              {showCartFilters ? t`Hide filters` : t`Show filters`}
            </span>
            {data.activeFilters > 0 && (
              <span className="flex h-[20px] w-[20px] items-center justify-center rounded-full bg-gray-500 text-xs text-black">
                {data.activeFilters}
              </span>
            )}
          </Button>
        </div>
        <div className="flex flex-none flex-col justify-end md:flex-1">
          <CartViewSwitcher />
        </div>
      </div>
      {!!showCartFilters && (
        <div className="flex flex-wrap items-center">
          <SortSelect
            sortOptions={sortOptions}
            referenceProps={{
              className: 'border-gray-100 hover:border-gray-300',
            }}
          />
          <Filters filterDefinitions={restFilterDefinitions} />
          <CartFilter
            filterDefinition={stockStatusFilterDefinition}
            hideSearch={true}
          />
          <ResetFiltersButton />
        </div>
      )}
    </div>
  )
}

export default Header
