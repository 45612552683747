import { useMutation } from 'react-query'
import queryClient from 'src/react-query-client'

import { patchCustomer } from 'src/api'

const useUpdateCustomer = (customerId, options) =>
  useMutation((customer) => patchCustomer({ customerId, customer }), {
    onSettled: () => {
      return queryClient.invalidateQueries('customers')
    },
    ...options,
  })

export default useUpdateCustomer
