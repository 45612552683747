import React, { Fragment } from 'react'
import { InfiniteQueryObserver, QueryObserver } from 'react-query'
import { Navigate, useParams } from 'react-router-dom'
import queryClient from 'src/react-query-client'

import useLocalStorageState from 'use-local-storage-state'

import { CompanyUser } from 'src/api/company-user-search/types'

import useCart from 'src/hooks/data/useCart'
import useDefaultCompanyUser from 'src/hooks/data/useDefaultCompanyUser'
import usePaginatedAvailabilities from 'src/hooks/data/usePaginatedAvailabilities'
import usePaginatedPrices from 'src/hooks/data/usePaginatedPrices'
import usePaginatedProducts, {
  UsePaginatedProductsArgs,
} from 'src/hooks/data/usePaginatedProducts'
import usePriceList from 'src/hooks/data/usePriceList'
import useUpdateCartItems from 'src/hooks/data/useUpdateCartItems'

import CartFooter from 'src/components/CartFooter'
import OrderBudget from 'src/components/OrderBudget'

import CheckoutButton from 'src/pages/Carts/CartGrid/components/CheckoutButton'
import Grid from 'src/pages/Carts/CartGrid/components/Grid'
import Header from 'src/pages/Carts/CartGrid/components/Header'

export const PAGE_SIZE = 24

const CartGrid = () => {
  const { cartId } = useParams()
  interface CartProps {
    companyUserReference: any
    deliveryDates: any
    filters: any
    items: any
    updatedAt: any
    currency: any
    isClaimed: any
    originalCustomer: any
    name: any
    id: any
  }

  const cartQuery = useCart(cartId, {
    staleTime: undefined,
    cacheTime: undefined,
    notifyOnChangeProps: 'tracked',
    select: ({
      companyUserReference,
      deliveryDates,
      filters,
      items,
      updatedAt,
      currency,
      isClaimed,
      originalCustomer,
      name,
      id,
    }: CartProps) => ({
      companyUserReference,
      deliveryDates,
      filters,
      items,
      updatedAt,
      currency,
      isClaimed,
      originalCustomer,
      name,
      id,
    }),
  })

  const cartQueryKey = React.useMemo(() => ['cart', cartId], [cartId])

  const defaultCompanyUserQuery = useDefaultCompanyUser<CompanyUser>({
    options: {
      enabled: !!cartQuery.data?.companyUserReference,
    },
  })
  const companyUser = defaultCompanyUserQuery.data

  const { data: priceList } = usePriceList(
    {
      companyId: companyUser?.companyId as string,
      companyUserReference: cartQuery.data?.companyUserReference,
    },
    {
      enabled: !!companyUser?.companyId,
    }
  )

  const priceListName = (priceList as { name: string })?.name

  const { fetchNextPage: fetchNextPricesPage } = usePaginatedPrices(
    {
      priceListName: priceListName,
      companyUserReference: cartQuery.data?.companyUserReference,
    },
    {
      enabled:
        !!priceListName &&
        !!cartQuery.data?.companyUserReference &&
        !!companyUser?.companyUserReference &&
        cartQuery.data?.companyUserReference ===
          companyUser?.companyUserReference,
    }
  )

  const { fetchNextPage: fetchNextAvailabilitiesPage } =
    usePaginatedAvailabilities(
      {
        companyUserReference: cartQuery.data?.companyUserReference,
      },
      {
        enabled:
          !!cartQuery.data?.companyUserReference &&
          !!companyUser?.companyUserReference &&
          cartQuery.data?.companyUserReference ===
            companyUser?.companyUserReference,
      }
    )

  const usePaginatedProductsProps: UsePaginatedProductsArgs = {
    companyUserReference: cartQuery.data?.companyUserReference,
    limit: PAGE_SIZE,
    sort: cartQuery.data?.filters?.searchOptions?.sort,
    filters: cartQuery.data?.filters?.searchOptions?.filters,
    searchTerm: cartQuery.data?.filters?.searchOptions?.searchTerm,
    onePerGroupHash: true,
  }

  const productsQuery = usePaginatedProducts(usePaginatedProductsProps, {
    onSuccess: ({ pages }: { pages: any[] }) => {
      const products = pages?.[pages.length - 1]?.abstractProducts as any[]
      fetchNextPricesPage({
        pageParam: products.map(({ abstractSku }) => abstractSku),
      })
      fetchNextAvailabilitiesPage({
        pageParam: products.map(({ id }) => id),
      })
    },
    enabled:
      !!priceListName &&
      !!cartQuery.data?.companyUserReference &&
      !!companyUser?.companyUserReference &&
      cartQuery.data?.companyUserReference ===
        companyUser?.companyUserReference,
  })

  if (productsQuery.isError) {
    throw productsQuery.error
  }

  const availabilityObserver = React.useMemo(
    () =>
      new InfiniteQueryObserver(queryClient, {
        refetchOnMount: false,
        queryKey: [
          'availability-periods-paginated',
          companyUser?.companyUserReference,
        ],
        select: ({ pages }) =>
          pages.reduce((collection, page) => ({ ...collection, ...page }), {}),
      }),
    [companyUser?.companyUserReference]
  )

  const pricesObserver = new InfiniteQueryObserver(queryClient, {
    refetchOnMount: false,
    queryKey: [
      'prices-paginated',
      priceListName,
      ...(companyUser?.companyUserReference
        ? [companyUser?.companyUserReference]
        : []),
    ],
    select: ({ pages }) =>
      pages.reduce((collection, page) => ({ ...collection, ...page }), {}),
  })

  const useCartObserver = (cartQueryKey: string) => {
    const [_, setOrphanedItemData] = useLocalStorageState(
      JSON.stringify(cartQueryKey),
      { defaultValue: null }
    )

    const observer = React.useMemo(
      () =>
        new QueryObserver(queryClient, {
          refetchOnMount: false,
          queryKey: cartQueryKey,
        }),
      [cartQueryKey]
    )

    React.useEffect(() => {
      const unsubscribe = observer.subscribe((query) => {
        const hasItems = Object.keys(query.data.items).length > 0
        if (hasItems) {
          setOrphanedItemData({
            items: query?.data?.items,
            updatedAt: new Date().toJSON(),
          })
        }
      })

      return unsubscribe
    }, [observer, cartQueryKey, setOrphanedItemData])

    return { observer, setOrphanedItemData }
  }

  const { observer: cartObserver } = useCartObserver(cartQueryKey)

  const { status: cartItemsUpdateStatus, mutate: updateCartItems } =
    useUpdateCartItems({
      companyUserId: cartQuery.data?.companyUserReference,
      cartId,
    })

  const hasActiveSearch = React.useMemo(
    () => !!cartQuery.data?.filters?.searchOptions?.searchTerm,
    [cartQuery.data?.filters?.searchOptions?.searchTerm]
  )

  if (cartQuery.isError && cartQuery?.error?.status === 404) {
    return <Navigate to=".." />
  }

  const items = productsQuery.data?.items
  const hasNextPage = productsQuery.hasNextPage
  const fetchNextPage = productsQuery.fetchNextPage

  return (
    <Fragment>
      {productsQuery.isSuccess && (
        <div className="flex grow flex-col">
          <div className="z-[1] flex space-x-1 bg-white p-4 drop-shadow-lg lg:space-x-2">
            <Header
              cartId={cartId!}
              filterDefinitions={productsQuery.data?.filters}
              sortOptions={productsQuery.data.sort}
            />
          </div>
          <Grid
            availabilityObserver={availabilityObserver}
            cartObserver={cartObserver}
            fetchNextPage={fetchNextPage}
            hasActiveSearch={hasActiveSearch}
            hasNextPage={hasNextPage || false}
            items={items}
            fetchNextPricesPage={fetchNextPricesPage}
            fetchNextAvailabilitiesPage={fetchNextAvailabilitiesPage}
            pricesObserver={pricesObserver}
            productsQuery={productsQuery}
            updateCartItems={updateCartItems}
          />
        </div>
      )}
      <div className="hidden md:block">
        <div className="mb-2 flex shrink-0 flex-col-reverse items-end space-y-2 space-y-reverse border-t border-gray-300 bg-white p-4 md:mb-0 md:flex-row md:items-center md:justify-between md:space-x-4 md:space-y-0">
          <div>
            <OrderBudget currencyIsoCode={'EUR'} />
          </div>
          <CartFooter cartItemsUpdateStatus={cartItemsUpdateStatus} />
        </div>
      </div>
      <div className="absolute bottom-4 right-4 z-20 md:hidden">
        <CheckoutButton
          companyUserReference={companyUser?.companyUserReference!}
        />
      </div>
    </Fragment>
  )
}

export default CartGrid
