import { privateClient } from 'src/api/api-client'
import { ErpOrderCancellationResponse } from 'src/api/erp-order-cancellation/erpOrderCancellationResponse'
import {
  ErpOrderCancellation,
  ErpOrderCancellationArgs,
} from 'src/api/erp-order-cancellation/types'
import { normalizePayloadForType } from 'src/api/utils'

type GetErpOrderCancellationProps = {
  uuid?: string
  args?: ErpOrderCancellationArgs
}

export const getErpOrderCancellations = async ({
  uuid,
  args,
}: GetErpOrderCancellationProps): Promise<any> => {
  const searchParams = new URLSearchParams()

  if (args?.debitorNumber !== undefined) {
    searchParams.append('debitor_number', args.debitorNumber)
  }

  if (args?.customerReference !== undefined) {
    searchParams.append('customer_reference', args.customerReference)
  }

  if (args?.companyUserReference !== undefined) {
    searchParams.append('company_user_reference', args.companyUserReference)
  }

  if (args?.externalReference !== undefined) {
    searchParams.append('external_reference', args.externalReference)
  }

  if (args?.limit !== undefined) {
    searchParams.append('page[limit]', String(args?.limit || 12))
  }

  if (args?.offset !== undefined) {
    searchParams.append('page[offset]', String(args?.offset || 0))
  }

  if (args?.sort !== undefined) {
    searchParams.append('sort', args?.sort)
  }

  if (args?.states !== undefined) {
    for (let i = 0; i < args.states.length; i++) {
      searchParams.append('states[]', args.states[i])
    }
  }

  const payload = await privateClient(
    !!uuid ? `erp-order-cancellation/${uuid}` : 'erp-order-cancellation/',
    {
      searchParams,
    }
  ).json()

  const [erpOrderCancellations] = normalizePayloadForType(
    payload,
    'erpOrderCancellation'
  )

  return erpOrderCancellations
}

export const postERPOrderCancelation = async (
  attributes: Partial<ErpOrderCancellation>
) =>
  await privateClient
    .post('erp-order-cancellation', {
      json: {
        data: {
          type: 'erp-order-cancellation',
          attributes,
        },
      },
    })
    .json<ErpOrderCancellationResponse>()

export const patchERPOrderCancelation = async (
  uuid: string,
  attributes?: Partial<ErpOrderCancellation>
) =>
  await privateClient
    .patch(`erp-order-cancellation/${uuid}`, {
      json: {
        data: {
          type: 'erp-order-cancellation',
          attributes,
        },
      },
    })
    .json<ErpOrderCancellationResponse>()

export const sendErpOrderCancellation = async ({
  state,
  ...attributes
}: ErpOrderCancellation) => {
  const createCancellationResponse = await postERPOrderCancelation(attributes)
  const {
    data: { id },
  } = createCancellationResponse
  const patchCancellationResponse = await patchERPOrderCancelation(id, {
    debitorNumber: attributes.debitorNumber,
    state,
  })
  return patchCancellationResponse
}
